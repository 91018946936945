import { ScribePage } from '@digitalscientists/react-scribe';
import ResponsiveLayout from '../templates/responsive-layout';
import YouTube from 'react-youtube';
import queryString from 'query-string';
const { useEffect, useState } = require('react');

export const Demo = () => {
    const [accessToken, setAccessToken] = useState(null);
    useEffect(() => {
        const user = localStorage?.getItem('user');
        const accessToken = user ? JSON.parse(user)['accessToken'] : null;
        setAccessToken(accessToken);
    }, [])

    const parsedHash = queryString.parse(window.location.search);

    const videoId = parsedHash.videoId || 'klAndlhimFQ';
    const opts = {
        height: '195',
        width: '320',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
        },
    };

    return <ResponsiveLayout title="Transcribe">
        <ScribePage
            api="wss://ws.staging.healthcontext.ai"
            mlApi={process.env.REACT_APP_ML_API_HOST}
            s3Bucket="scribe-recordings-dev"
            preauthorizedToken={accessToken}
        />
        <div
            style={{
                position: 'absolute',
                bottom: 0,
                right: 0
            }}
        >
            <YouTube videoId={videoId} opts={opts} />
        </div>
    </ResponsiveLayout>
}

export default Demo;