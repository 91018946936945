import { useHandleSignInCallback, useLogto } from '@logto/react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../contexts/user-context';
import jwtDecode from 'jwt-decode';
export interface JWTPayload {
    jti: string;
    sub: string;
    iat: number;
    exp: number;
    scope: string;
    client_id: string;
    iss: string;
    aud: string;
}
export const Callback = () => {
    const [_user, setUser] = useContext(UserContext);
    
    const {
        getAccessToken,  
        signOut,
    } = useLogto();

    const navigate = useNavigate();
    
    const { isLoading } = useHandleSignInCallback(async () => {
        try {
            const accessToken = await getAccessToken(process.env.REACT_APP_API_HOST);

            if (!accessToken) { throw new Error('No accessToken'); }

            const payload : JWTPayload = jwtDecode(accessToken);
            const user = {
                accessToken,
                scopes: payload.scope.split(' '),
                expiresAt: payload['exp'] * 1000,
            };
            setUser(user);
            localStorage.setItem('user', JSON.stringify(user));
            navigate("/transcribe");
        } catch (error) {
            console.error(error);
            localStorage.removeItem('user');
            signOut(document.location.origin);
        }
    });

    // When it's working in progress
    return (<div>Redirecting...</div>);
};

export default Callback;