import { ScribePage } from '@digitalscientists/react-scribe';
import ResponsiveLayout from '../templates/responsive-layout';
import { useEffect, useState, useContext } from 'react';
import UserContext from '../contexts/user-context';

export const Transcribe = () => {
    const [accessToken, setAccessToken] = useState(null);
    const [user, _setUser] = useContext(UserContext);    
    useEffect(() => {
        const user = localStorage?.getItem('user');
        const accessToken = user ? JSON.parse(user)['accessToken'] : null;
        setAccessToken(accessToken);
    }, [user])
    return <ResponsiveLayout title="Transcribe">
        <ScribePage
            api="wss://ws.staging.healthcontext.ai"
            mlApi={process.env.REACT_APP_ML_API_HOST}
            s3Bucket="scribe-recordings-dev"
            preauthorizedToken={accessToken}
        />
    </ResponsiveLayout>
}

export default Transcribe;