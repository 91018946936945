import { useLogto } from "@logto/react";
import ResponsiveLayout from "../templates/responsive-layout";

export const SignOut = () => {
    const { signOut } = useLogto();

    function handleSignOut() {
        localStorage.removeItem('user');
        signOut(document.location.origin);
    }
  
    return (
        <ResponsiveLayout title="Sign Out">
            <button onClick={handleSignOut}>
                Sign out
            </button>
        </ResponsiveLayout>
    );
};

export default SignOut;