import { createContext, Dispatch, SetStateAction } from 'react';

export interface UserContextState {
  id?: string;
  accessToken?: string;
  scopes?: string[];
  expiresAt?: number;
}

export type UserContextSetState = Dispatch<SetStateAction<UserContextState>>;

const UserContext = createContext<[UserContextState, UserContextSetState]>([{}, () => {}]);

export default UserContext;