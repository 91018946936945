import { useLogto } from "@logto/react";

export const Subscribe = () => {
    const { signOut } = useLogto();    
    return <div>
        Subscribe to one of the following plans to proceed.
        <hr />
        <button onClick={() => signOut(document.location.origin)}>
            Sign out
        </button>        
    </div>
}

export default Subscribe;