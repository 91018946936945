import { useLogto } from "@logto/react";
import { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import jwtDecode from 'jwt-decode';
import UserContext from "../contexts/user-context";
import { Subscribe } from "../pages";
import { JWTPayload } from "../pages/callback";

export const AuthLayout = ({ children }) => {
  const { signIn, isAuthenticated, getAccessToken } = useLogto();   

  const navigate = useNavigate(); 
  const location = useLocation();  
  const [user, setUser] = useContext(UserContext);
  const [expiresAt, setExpiresAt] = useState(user?.expiresAt || 0);
  if (isAuthenticated && expiresAt < Date.now()) {
    if (localStorage.getItem('loading') !== 'true') {
      localStorage.setItem('loading', 'true');
      getAccessToken(process.env.REACT_APP_API_HOST).then((accessToken) => {
        localStorage.removeItem('loading');
        const payload : JWTPayload = jwtDecode(accessToken);
        if (accessToken && payload['exp']) {
          const user = {
            accessToken,
            scopes: payload.scope.split(' '),
            expiresAt: payload['exp'] * 1000,
          };        
          setUser(user);
          localStorage.setItem('user', JSON.stringify(user));
          setExpiresAt(user.expiresAt);
        } else {      
          localStorage.removeItem('user');
          if (location.pathname !== '/signout') {
            navigate("/signout");
          }
        }
      });
    }
  }
  return (
    <div>
      {(!isAuthenticated) && (
        <button onClick={() => signIn(`${document.location.origin}/callback`)}>
          Sign In
        </button>
      )}
      { isAuthenticated && user.scopes?.includes('user:active') && children }
      { isAuthenticated && !user.scopes?.includes('user:active') && <Subscribe /> }
    </div>
  );
}

export default AuthLayout;