import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LogtoProvider, LogtoConfig } from '@logto/react';
import { Analytics, Calendar, Home, Settings, Transcribe, Demo, Callback, SignOut } from './pages';
import UserContext, { UserContextState } from './contexts/user-context';

const config: LogtoConfig = {
  endpoint: 'https://logto.staging.healthcontext.ai/',
  appId: process.env.REACT_APP_APP_ID,
  resources: [
    process.env.REACT_APP_API_HOST
  ],
  scopes: ['user:conversations:list', 'user:conversations:read', 'user:conversations:write', 'user:conversations:delete', 'user:active', 'user:ml:tasks'],
};

function getInitialUserState() {
  const user = localStorage.getItem('user')
  return user ? JSON.parse(user) : {
    id: undefined,
    accessToken: undefined,
    scopes: undefined,
    expiresAt: undefined,
  }
}

function App() {
  const [user, setUser] = useState<UserContextState>(getInitialUserState());

  return (
    <LogtoProvider config={config}> 
      <UserContext.Provider value={[user, setUser]}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/transcribe" element={<Transcribe />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/settings" element={<Settings />} /> 
            <Route path="/callback" element={<Callback />} />
            <Route path="/demo" element={<Demo />} />
            <Route path="/signout" element={<SignOut />} />               
          </Routes>
        </Router>
      </UserContext.Provider>
    </LogtoProvider>
  );
}

export default App;
