import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import HomeIcon from '../assets/House.svg';
import PhoneCallIcon from '../assets/PhoneCall.svg';
import CalendarIcon from '../assets/CalendarBlank.svg';
import ChartBarIcon from '../assets/ChartBarHorizontal.svg';
import IdentificationIcon from '../assets/IdentificationBadge.svg';
import SignOutIcon from '../assets/SignOut.svg';

import { NavLink } from "react-router-dom";
import logo from '../assets/logo.png';
import styles from './responsive-layout.module.css';
import classNames from 'classnames';
import { useLogto } from "@logto/react";
import AuthLayout from './auth-layout';

const drawerWidth = 240;

interface Props {
  children: React.ReactNode;
  title: string;
}

export default function ResponsiveLayout(props: Props) {
  const { children } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { isAuthenticated } = useLogto();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menu = [
    {
      path: "/",
      name: "Home",
      icon: <img src={HomeIcon} alt="home" className={styles.icon} />,
      authenticationRequired: true      
    }, 
    {
      path: "/calendar",
      name: "Calendar",
      icon: <img src={CalendarIcon} alt="calendar" className={styles.icon} />,
      authenticationRequired: true      
    },     
    {
      path: "/transcribe",
      name: "Recordings", 
      icon: <img src={PhoneCallIcon} alt="phone call" className={styles.icon} />,
      authenticationRequired: true      
    },
    {
      path: "/analytics",
      name: "Analytics",
      icon: <img src={ChartBarIcon} alt="chart bar" className={styles.icon} />,
      authenticationRequired: true      
    },
    {
      path: "/settings",
      name: "Settings",
      icon: <img src={IdentificationIcon} alt="identification badge" className={styles.icon} />,
      authenticationRequired: true      
    },
    {
      path: "/signout",
      name: "Sign Out", 
      icon: <img src={SignOutIcon} alt="sign out" className={styles.icon} />,
      authenticationRequired: true
    }    
  ];

  const drawer = (
    <div>
      <Toolbar>
        <img src={logo} alt="logo" className={styles.logo} />
      </Toolbar>
      <List>
        {menu.filter(r => r.authenticationRequired ? isAuthenticated : true).map((route, index) => (
          <NavLink
            key={`${route.name}-${route.path}`}
            to={route.path} 
            className={({ isActive }) =>
              classNames({[`${styles['nav-link']}`]: true, [`${styles['selected']}`]: isActive})
            }
          >
            <ListItem key={route.name} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {route.icon}
                </ListItemIcon>
                <ListItemText primary={route.name} />
              </ListItemButton>
            </ListItem>              
          </NavLink>        
        ))}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          boxShadow: 'none',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ 
          width: { sm: drawerWidth }, flexShrink: { sm: 0 },
          backgroundColor: '#0057FF',
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#0057FF', border: 'none' }, 
            '& .MuiListItemText-primary': { color: '#fff' },    
            '& .MuiSvgIcon-root': { color: '#fff' },                                                
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: '#0057FF', border: 'none' },  
            '& .MuiListItemText-primary': { color: '#fff' },
            '& .MuiSvgIcon-root': { color: '#fff' },
          }}
          open
        >          
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        className={styles.containerBackground}
        sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <div className={styles.container}>
          <AuthLayout>
            {children}
          </AuthLayout>
        </div>
      </Box>
    </Box>
  );
}